<template>
  <!-- eslint-disable max-len -->
  <div>
    <form>
      <div id="registrierung" class="row abstand-unten-17">
        <div class="col-xl-8  col-lg-6 col-md-8 col-12">
            <div class="row">
              <div class="col-12 abstand-unten-17">
                <label class="aufschrift copy_bold_12" id="vorraussetzung-aufschrift">
                  Unter welchen Voraussetzungen können Sie sich online registrieren?
                </label>
                <div @click.self="toggleHinweisVoraussetzungen"
                     class="hilfe-icon-voraussetzungen"
                     :class="{'vgh-blau-bg': mandant === 1,
                    'oesa-gruen-bg': mandant === 3,
                    'oevo-blau-bg': mandant === 5}">?
                </div>
                <div v-if="showHinweisVoraussetzungen">
                  <div class="aufschrift copy_bold_12" style="margin-top: 0">
                    <span>
                    <br/>
                        1. Sie sind Versicherungsnehmer/-in eines bestehenden Versicherungsvertrages.<br/><br/>
                        2. Ihre <a v-if="mandant === 3">ÖSA-</a><a v-else-if="mandant === 5"/><a
                      v-else>VGH-</a>Verträge werden nicht ausschließlich von einer Sparkasse und/oder von einem Makler betreut.<br/><br/>
                        3. Sie sind weder ein Firmenkunde, noch sind Sie gemeinschaftlich mit einer anderen Person Versicherungsnehmer/-in eines Versicherungsvertrages.<br/><br/>
                        Hinweis: Sollte mindestens einer dieser Fälle <b>nicht</b> zutreffen, wenden Sie sich bitte an Ihre
                        <a v-if="mandant === 3">ÖSA-Agentur</a><a v-else-if="mandant === 5">Vertretung der Öffentlichen</a><a
                      v-else>VGH-Agentur</a> oder an das
                        <a v-if="mandant === 3">ÖSA-Supportteam</a><a v-else-if="mandant === 5">Kundenservicecenter der Öffentlichen</a><a
                      v-else>Supportteam</a>.<br/><br/>
                        Weitere Informationen zur Registrierung finden Sie
                        <a v-if="mandant === 3" href="https://www.oesa.de/kundenportal">hier.</a>
                        <a v-else-if="mandant === 5"
                           href="https://www.oeffentlicheoldenburg.de/kundenportal">hier.</a>
                        <a v-else href="https://www.vgh.de/content/privat/meine-vgh">hier.</a>
                    </span>
                </div>
              </div>
            </div>
            <div class="col-12">
              <label class="aufschrift copy_bold_12" for="vorname">Vorname *</label>
              <input class="eingabe copy_16"
                     :class="{
                           'eingabe-fehler': errors.vorname,
                           'vgh-eingabe': mandant === 1,
                           'oesa-eingabe': mandant === 3,
                           'oevo-eingabe': mandant === 5,
                           'fehler-oevo': errors.vorname && mandant === 5}"
                     v-model="vorname"
                     maxlength="30"
                     id="vorname"
                     @input="errors.vorname = false">
              <label v-if="errors.vorname"
                     class="aufschrift fehler-text copy_bold_12"
                     :class="{'fehler-text-oevo': mandant === 5}">
                {{ vornameFehlerText }}
              </label>
            </div>
            <div class="col-12">
              <label class="aufschrift copy_bold_12" for="nachname">Name *</label>
              <input class="eingabe copy_16"
                     :class="{'eingabe-fehler': errors.nachname,
                           'vgh-eingabe': mandant === 1,
                           'oesa-eingabe': mandant === 3,
                           'oevo-eingabe': mandant === 5,
                           'fehler-oevo': errors.nachname && mandant === 5}"
                     v-model="nachname"
                     maxlength="30"
                     id="nachname"
                     @input="errors.nachname = false">
              <label v-if="errors.nachname"
                     class="aufschrift fehler-text copy_bold_12"
                     :class="{'fehler-text-oevo': mandant === 5}">
                {{ nameFehlerText }}
              </label>
            </div>
            <div class="col-12">
              <label class="aufschrift copy_bold_12" for="benutzername">E-Mailadresse *</label>
              <input class="eingabe copy_16"
                     autocapitalize="off"
                     autocomplete="off"
                     spellcheck="false"
                     :class="{'eingabe-fehler': errors.emailAdresse,
                           'vgh-eingabe': mandant === 1,
                           'oesa-eingabe': mandant === 3,
                           'oevo-eingabe': mandant === 5,
                           'fehler-oevo': errors.emailAdresse && mandant === 5}"
                     v-model="emailAdresse"
                     maxlength="150"
                     @keydown.space.prevent
                     @input="errors.emailAdresse = false"
                     v-on:input="emailLeerzeichenEntfernen"
                     id="benutzername">
              <label v-if="errors.emailAdresse"
                     class="aufschrift fehler-text copy_bold_12"
                     :class="{'fehler-text-oevo': mandant === 5}">
                {{ emailFehlerText }}
              </label>
            </div>
            <div class="col-12">
              <label class="aufschrift copy_bold_12" for="benutzername-bestaetigen">
                E-Mailadresse bestätigen *</label>
              <input class="eingabe copy_16"
                     autocapitalize="off"
                     autocomplete="off"
                     spellcheck="false"
                     :class="{'eingabe-fehler': errors.emailBestaetigung,
                           'vgh-eingabe': mandant === 1,
                           'oesa-eingabe': mandant === 3,
                           'oevo-eingabe': mandant === 5,
                           'fehler-oevo': errors.emailBestaetigung && mandant === 5}"
                     v-model="emailBestaetigung"
                     maxlength="150"
                     @keydown.space.prevent
                     @input="errors.emailBestaetigung = false"
                     v-on:input="emailLeerzeichenEntfernen"
                     id="benutzername-bestaetigen">
              <label v-if="errors.emailBestaetigung"
                     class="aufschrift fehler-text copy_bold_12"
                     :class="{'fehler-text-oevo': mandant === 5}">
                {{ emailBestaetigungFehlerText }}
              </label>
            </div>
            <div class="col-12">
              <label class="aufschrift copy_bold_12" for="versicherungsscheinnummer">
                Versicherungsscheinnummer *</label>
              <input class="eingabe copy_16"
                     :class="{'eingabe-fehler': errors.vsnr,
                           'vgh-eingabe': mandant === 1,
                           'oesa-eingabe': mandant === 3,
                           'oevo-eingabe': mandant === 5,
                           'fehler-oevo': errors.vsnr && mandant === 5}"
                     v-model="vsnr"
                     maxlength="21"
                     id="versicherungsscheinnummer"
                     @input="checkInput">
              <label v-if="errors.vsnr"
                     class="aufschrift fehler-text copy_bold_12"
                     :class="{'fehler-text-oevo': mandant === 5}">
                Bitte geben Sie eine gültige Versicherungsscheinnummer ein.
              </label>
            </div>
            <div class="col-12 abstand-unten-17">
              <div id="handynummer-hinweis">
                <label class="aufschrift copy_bold_12" for="handynummer">
                  Handynummer *</label>
                <div @click.self="toggleHinweisHN"
                     class="hilfe-icon"
                     :class="{'vgh-blau-bg': mandant === 1,
                        'oesa-gruen-bg': mandant === 3,
                        'oevo-blau-bg': mandant === 5}">?
                  <div v-if="showHinweisHN" class="hilfe-text">
                    <div class="row">
                      <div class="col-12">
                        <div id="close-btn" @click="schliesseHinweisHN">
                          <em class="fa-solid fa-xmark"></em>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12">
                          <span class="hinweis-text-span">
                          Die Zwei-Faktor-Authentifizierung ist eine zusätzliche
                          Sicherheitsmaßnahme zum Schutz von Ihrem Benutzerkonto.
                          Zusätzlich zum Passwort muss ein SMS-Code beim Login als
                          weitere Sicherheitskomponente eingeben werden. Es wird
                          hierzu eine deutsche Mobilfunknummer benötigt.
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div class="eingabe-form copy_16"
                     :class="{'eingabe-fehler': errors.handynummer,
                           'vgh-eingabe-form': mandant === 1,
                           'oesa-eingabe-form': mandant === 3,
                           'oevo-eingabe-form': mandant === 5,
                           'fehler-oevo': errors.handynummer && mandant === 5}">
                  <span class="dropdown dropdown-button"
                          @click.stop.prevent="toggleVorwahlnummerDropdown">
                    <span v-if="vorwahlnummer === '+49'" class="fi fi-de dropdown-bild"></span>
                    <span v-if="vorwahlnummer === '+31'" class="fi fi-nl dropdown-bild"></span>
                    <em class="fa-solid fa-chevron-down"/>
                  </span>
                  <input class="eingabe eingabe-handynummer copy_16"
                         style="border:none;"
                         v-model="handynummerEingabe"
                         maxlength="15"
                         id="handynummer"
                         @keypress="nurZahlen"
                         @paste="behandleEinfuegen"
                         @input="errors.handynummer = false">
                </div>
                <ul v-show="showVorwahlnummerDropdown" class="dropdown-inhalt">
                  <li
                    class="dropdown-auswahl copy_12"
                    :class="{'vgh-blau-bg-hover dropdown-hover-text': mandant === 1,
                             'oesa-gruen-bg-hover dropdown-hover-text': mandant === 3,
                            'oevo-blau-bg-hover dropdown-hover-text': mandant === 5}"
                    @click="vorwahlnummerAuswahl('+49')">
                    <span class="fi fi-de dropdown-bild"></span> &nbsp; Deutschland +49
                  </li>
                  <li
                    class="dropdown-auswahl copy_12"
                    :class="{'vgh-blau-bg-hover dropdown-hover-text': mandant === 1,
                            'oesa-gruen-bg-hover dropdown-hover-text': mandant === 3,
                            'oevo-blau-bg-hover dropdown-hover-text': mandant === 5}"
                    @click="vorwahlnummerAuswahl('+31')">
                    <span class="fi fi-nl dropdown-bild"></span> &nbsp; Niederlande +31
                  </li>
                </ul>
                <label v-if="errors.handynummer"
                       class="aufschrift fehler-text copy_bold_12"
                       :class="{'fehler-text-oevo': mandant === 5}">
                  Bitte geben Sie eine gültige
                  {{ vorwahlnummer === '+49' ? 'deutsche' : 'niederländische' }} Handynummer ein.
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row abstand-unten-17">
        <div class="col-xl-12 bg-light textbox-grau">
          <p class="copy_bold_12">
            Bitte bestätigen Sie die Nutzungsbedingungen,
            die Einwilligungserklärung und die Werbeeinwilligung.
          </p>
          <p class="copy_12">
            <input type="checkbox"
                   id="cNutzungsbedingung"
                   :class="{'eingabe-fehler': errors.nutzungsbedingung,
                       'input-vgh': mandant === 1,
                       'input-oesa': mandant === 3,
                       'input-oevo': mandant === 5}"
                   v-model="nutzungsbedingung">
            <label for="cNutzungsbedingung">
              Hiermit bestätige ich, dass ich die
              <a :href="nutzungsbedingungenLink"
                 :class="{'vgh-blau-color': mandant === 1,
                    'vgh-blau-text-decoration-hover': mandant === 1,
                    'oesa-gruen-color': mandant === 3,
                    'oesa-gruen-text-decoration-hover': mandant === 3,
                    'oevo-blau-color': mandant === 5,
                    'oevo-blau-text-decoration-hover': mandant === 5}"
                 target="_blank"
              >
                Nutzungsbedingungen
              </a> gelesen und akzeptiert habe. *
            </label>
            <label v-if="errors.nutzungsbedingung"
                   class="aufschrift fehler-text copy_bold_12"
                   :class="{'fehler-text-oevo': mandant === 5}">
              Bitte bestätigen Sie die Nutzungsbedingungen.
            </label>
          </p>
          <p class="copy_12">
            <input type="checkbox"
                   id="cEinwilligung"
                   :class="{'eingabe-fehler': errors.einwilligungKsp,
                       'input-vgh': mandant === 1,
                       'input-oesa': mandant === 3,
                       'input-oevo': mandant === 5}"
                   v-model="einwilligungKsp"
                   checked>
            <label for="cEinwilligung">
              Hiermit bestätige ich, dass ich die
              <a :href="eweLink"
                 :class="{'vgh-blau-color': mandant === 1,
                  'vgh-blau-text-decoration-hover': mandant === 1,
                  'oesa-gruen-color': mandant === 3,
                  'oesa-gruen-text-decoration-hover': mandant === 3,
                  'oevo-blau-color': mandant === 5,
                  'oevo-blau-text-decoration-hover': mandant === 5}"
                 target="_blank"
              >
                Einwilligung zur Nutzung des Kundenportals</a> gelesen und akzeptiert habe. *
            </label>
            <label v-if="errors.einwilligungKsp"
                   class="aufschrift fehler-text copy_bold_12"
                   :class="{'fehler-text-oevo': mandant === 5}">
              Bitte bestätigen Sie die Einwilligungserklärung.
            </label>
          </p>
          <p class="copy_12">
            <input type="checkbox" id="cWerbung" v-model="werbeeinwilligung"
                   :class="{'input-vgh': mandant === 1,
                      'input-oesa': mandant === 3,
                      'input-oevo': mandant === 5}">
            <label for="cWerbung">
              Hiermit bestätige ich, dass ich die
              <a :href="werbeeinwilligungLink"
                 :class="{'vgh-blau-color': mandant === 1,
                  'vgh-blau-text-decoration-hover': mandant === 1,
                  'oesa-gruen-color': mandant === 3,
                  'oesa-gruen-text-decoration-hover': mandant === 3,
                  'oevo-blau-color': mandant === 5,
                  'oevo-blau-text-decoration-hover': mandant === 5}"
                 target="_blank"
              >
                Werbeeinwilligung zur Nutzung des Kundenportals</a> gelesen und akzeptiert habe.
            </label>
          </p>
        </div>
      </div>
    </form>
    <label id="pflicht-label" class="copy_12 abstand-unten-17">Pflichtfeld*</label>
    <div class="row abstand-unten-40">
      <div class="col-12">
        <input type="submit"
               id="submit-btn"
               class="button-ivv white"
               :class="{'button-vgh': mandant === 1,
                  'button-oesa': mandant === 3,
                  'button-oevo': mandant === 5,
                  'button-disabled': weiterButtonDisabled}"
               value="Weiter"
               :disabled="weiterButtonDisabled"
               @click.prevent="registrieren"
        >
      </div>
    </div>
    <div v-if="errors.backendFehler" class="row abstand-unten-17">
      <div class="col-xl-10">
        <p class="fehler-text"
           :class="{'fehler-text-oevo': mandant === 5}">
          {{ backendFehlerText }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/backend-api';
import sicherheitsabfrage from '@/sicherheitsabfrage';

export default {
  name: 'Registrierungsdaten',
  props: ['mandant'],
  mixins: [sicherheitsabfrage],
  data() {
    return {
      vorname: '',
      nachname: '',
      emailAdresse: '',
      emailBestaetigung: '',
      vsnr: '',
      vorwahlnummer: '+49',
      handynummer: '',
      handynummerEingabe: '',
      nutzungsbedingung: false,
      einwilligungKsp: false,
      werbeeinwilligung: false,
      emailVersendet: false,
      fehlerText: 'Feld darf nicht leer sein',
      errors: {
        vorname: false,
        nachname: false,
        emailAdresse: false,
        emailBestaetigung: false,
        vsnr: false,
        handynummer: false,
        nutzungsbedingung: false,
        einwilligungKsp: false,
        backendFehler: false,
      },
      showHinweisHN: false,
      showHinweisVoraussetzungen: false,
      showVorwahlnummerDropdown: false,
      backendFehlerText: '',
      vornameFehlerText: '',
      nameFehlerText: '',
      emailFehlerText: '',
      emailBestaetigungFehlerText: '',
      weiterButtonDisabled: false,
    };
  },
  computed: {
    eweLink() {
      if (this.mandant === 1) {
        return 'https://www.vgh.de/meine_vgh_einwilligungserkl%C3%A4rung';
      }
      if (this.mandant === 3) {
        return 'https://www.oesa.de/meine_oesa_einwilligungserklaerung';
      }
      if (this.mandant === 5) {
        return 'https://www.oeffentlicheoldenburg.de/kundenportal_einwilligungserklaerung';
      }
      return '';
    },
    nutzungsbedingungenLink() {
      if (this.mandant === 1) {
        return 'https://www.vgh.de/meine_vgh_nutzungsbedingungen';
      }
      if (this.mandant === 3) {
        return 'https://www.oesa.de/meine_oesa_nutzungsbedingungen';
      }
      if (this.mandant === 5) {
        return 'https://www.oeffentlicheoldenburg.de/kundenportal_nutzungsbedingungen';
      }
      return '';
    },
    werbeeinwilligungLink() {
      if (this.mandant === 1) {
        return 'https://www.vgh.de/meine_vgh_werbeeinwilligung';
      }
      if (this.mandant === 3) {
        return 'https://www.oesa.de/meine_oesa_werbeeinwilligung';
      }
      if (this.mandant === 5) {
        return 'https://www.oeffentlicheoldenburg.de/kundenportal_werbeeinwilligung';
      }
      return '';
    },
  },
  methods: {
    toggleHinweisHN() {
      this.showHinweisHN = !this.showHinweisHN;
    },
    toggleHinweisVoraussetzungen() {
      this.showHinweisVoraussetzungen = !this.showHinweisVoraussetzungen;
    },
    schliesseHinweisHN() {
      this.showHinweisHN = false;
    },
    toggleVorwahlnummerDropdown() {
      this.showVorwahlnummerDropdown = !this.showVorwahlnummerDropdown;
    },
    vorwahlnummerAuswahl(vorwahlnummer) {
      this.vorwahlnummer = vorwahlnummer;
      this.toggleVorwahlnummerDropdown();
    },

    async registrieren() {
      try {
        this.resetValidierung();
        this.validate();
        if (!this.checkeEingabeFehler()) {
          this.weiterButtonDisabled = true;
          const formData = new FormData();
          formData.append('vorname', this.vorname);
          formData.append('nachname', this.nachname);
          formData.append('email', this.emailAdresse);
          formData.append('vsnr', this.vsnr);
          formData.append('handynummer', this.handynummer);
          formData.append('nutzungsbedingungen', this.nutzungsbedingung);
          formData.append('einwilligung', this.einwilligungKsp);
          formData.append('werbeeinwilligung', this.werbeeinwilligung);
          await api.sendeRegistrierungsdaten(
            formData,
          );
          this.$store.state.registrierungsdaten.vorname = this.vorname;
          this.$store.state.registrierungsdaten.nachname = this.nachname;
          this.$store.state.registrierungsdaten.emailAdresse = this.emailAdresse;
          this.$store.state.registrierungsdaten.vsnr = this.vsnr;
          this.$store.state.registrierungsdaten.handynummer = this.handynummer;
          this.$store.state.registrierungsdaten.nutzungsbedingungen = this.nutzungsbedingung;
          this.$store.state.registrierungsdaten.einwilligung = this.einwilligungKsp;
          this.$store.state.registrierungsdaten.werbeeinwilligung = this.werbeeinwilligung;
          this.$emit('naechster-schritt', 2);
          this.emailVersendet = true;
        }
      } catch (e) {
        console.log(e);
        this.errors.backendFehler = true;
        this.weiterButtonDisabled = false;
        console.log(e.response.data.statusTyp);
        switch (e.response.data.statusTyp) {
          case 'UNGUELTIGE_ANGABE':
            this.backendFehlerText = 'Bitte überprüfen Sie Ihre Angaben.';
            break;
          case 'REGISTRIERUNG_NICHT_MOEGLICH':
            this.backendFehlerText = 'Leider können Sie sich nicht beim Kundenportal anmelden. Bitte kontaktieren Sie Ihre/n Vertriebspartner/in.';
            break;
          case 'FEHLERHAFTE_LAENGE':
            this.backendFehlerText = 'Die maximale Zeichenlänge wurde überschritten. Bitte überprüfen Sie Ihre Angaben.';
            break;
          case 'FEHLERHAFTE_ZEICHEN':
            this.backendFehlerText = 'Sie haben nicht erlaubte Sonderzeichen verwendet. Bitte überprüfen Sie Ihre Angaben.';
            break;
          case 'NUTZUNGSBEDINGUNGEN_NICHT_AKZEPTIERT':
            this.backendFehlerText = 'Sie haben den Nutzungsbedingungen nicht zugestimmt.';
            break;
          case 'EINWILLIGUNGSERKLAERUNG_NICHT_AKZEPTIERT':
            this.backendFehlerText = 'Sie haben der Einwilligungserklärung nicht zugestimmt.';
            break;
          default:
            this.backendFehlerText = 'Es gab einen technischen Fehler.';
        }
      }
    },
    checkInput() {
      this.errors.vsnr = false;
      if (!(this.vsnr.length === 5 && this.vsnr
        .charAt(this.vsnr.length - 1) === '-')
        && !(this.vsnr.length === 9 && this.vsnr
          .charAt(this.vsnr.length - 1) === '.')
        && !(this.vsnr.length === 13 && this.vsnr
          .charAt(this.vsnr.length - 1) === '.')
        && !(this.vsnr.length === 17 && this.vsnr
          .charAt(this.vsnr.length - 1) === ' ')
      ) {
        const regEx = new RegExp(/[0-9]$/);
        if (!regEx.test(this.vsnr
          .charAt(this.vsnr.length - 1))) {
          this.vsnr = this.vsnr
            .replace(this.vsnr.substring(this.vsnr.length - 1), '');
        }
        this.insertTrennzeichen();
      }
    },
    insertTrennzeichen() {
      this.vsnr = this.vsnr.replace(/[^0-9]/g, '');
      if (this.vsnr.length > 4) {
        this.vsnr = `${this.vsnr.substring(0, 4)}-${this.vsnr.substring(4)}`;
      }
      if (this.vsnr.length > 8) {
        this.vsnr = `${this.vsnr.substring(0, 8)}.${this.vsnr.substring(8)}`;
      }
      if (this.vsnr.length > 12) {
        this.vsnr = `${this.vsnr.substring(0, 12)}.${this.vsnr.substring(12)}`;
      }
      if (this.vsnr.length > 16) {
        this.vsnr = `${this.vsnr.substring(0, 16)} ${this.vsnr.substring(16)}`;
      }
    },
    checkeEingabeFehler() {
      if (!this.errors.vorname
        && !this.errors.nachname
        && !this.errors.emailAdresse
        && !this.errors.emailBestaetigung
        && !this.errors.vsnr
        && !this.errors.handynummer
        && !this.errors.nutzungsbedingung
        && !this.errors.einwilligungKsp) {
        return false;
      }
      return true;
    },
    eingegeben() {
      if (this.vorname
        || this.nachname
        || this.vsnr
        || this.emailAdresse
        || this.emailBestaetigung
        || this.handynummerEingabe) {
        return true;
      }
      return false;
    },
    nurZahlen(eingabe) {
      let regEx = new RegExp(/^[0-9]*$/);
      if (this.handynummerEingabe.length === 0) {
        regEx = new RegExp(/^[1-9]*$/);
      }
      if (!regEx.test(eingabe.key)) {
        eingabe.preventDefault();
      }
    },
    behandleEinfuegen(value) {
      value.preventDefault();
      let eingefuegtes = value.clipboardData.getData('text/plain');
      eingefuegtes = eingefuegtes.replace(/^[^1-9]+|\D/g, '');
      this.handynummerEingabe = eingefuegtes;
    },
    gueltigerName(name) {
      const regEx = new RegExp(/^([a-zA-ZäöüÄÖÜß]+['-]?[ ]*)+[a-zA-ZäöüÄÖÜß]*$/);
      return regEx.test(name);
    },
    validate() {
      this.validateVorname(true);
      this.validateNachname(true);
      this.validateEmail(true);
      this.validateEmailBestaetigung();
      this.validateVSNR();
      this.validateHandynummer();
      this.validateNutzungsbedingungen(true);
      this.validateEinwilligungKsp(true);
    },
    validateVorname(istLeerCheck) {
      if (this.vorname === '' && istLeerCheck) {
        this.errors.vorname = true;
        this.vornameFehlerText = 'Bitte geben Sie Ihren Vornamen ein.';
      } else if (this.vorname && !this.gueltigerName(this.vorname)) {
        this.errors.vorname = true;
        this.vornameFehlerText = 'Bitte achten Sie auf ungültige Zeichen.';
      } else {
        this.errors.vorname = false;
      }
    },
    validateNachname(istLeerCheck) {
      if (this.nachname === '' && istLeerCheck) {
        this.errors.nachname = true;
        this.nameFehlerText = 'Bitte geben Sie Ihren Nachnamen ein.';
      } else if (this.nachname && !this.gueltigerName(this.nachname)) {
        this.errors.nachname = true;
        this.nameFehlerText = 'Bitte achten Sie auf ungültige Zeichen.';
      } else {
        this.errors.nachname = false;
      }
    },
    validateVSNR() {
      const regEx = new RegExp(/^[0-9]{4}[-][0-9]{3}[.][0-9]{3}[.][0-9]{3}([ ][0-9]{4})?$/);
      this.vsnr = this.vsnr.trim();
      if (!regEx.test(this.vsnr)) {
        this.errors.vsnr = true;
      } else {
        this.errors.vsnr = false;
      }
    },
    validateEmail(istLeerCheck) {
      if (!this.emailAdresse && istLeerCheck) {
        this.errors.emailAdresse = true;
        this.emailFehlerText = 'Bitte geben Sie Ihre E-Mail-Adresse ein.';
      }
      if (this.emailAdresse) {
        const regEx = new RegExp(/^[\w!#$%&'*+/=?`{|}~^-]+(?:\.[\w!#$%&'*+/=?`{|}~^-]+)*@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,6}$/);
        if (!regEx.test(this.emailAdresse)) {
          this.errors.emailAdresse = true;
          this.emailFehlerText = 'Bitte geben Sie Ihre gültige E-Mail-Adresse ein.';
        } else {
          this.errors.emailAdresse = false;
        }
      }
    },
    emailLeerzeichenEntfernen() {
      this.emailAdresse = this.emailAdresse.replace(/\s/g, '');
      this.emailBestaetigung = this.emailBestaetigung.replace(/\s/g, '');
    },
    validateHandynummer() {
      const regEx = new RegExp(/^[1-9][0-9]{0,14}$/);
      if (!this.handynummerEingabe || !regEx.test(this.handynummerEingabe)) {
        this.errors.handynummer = true;
      } else {
        this.errors.handynummer = false;
        this.handynummer = `${this.vorwahlnummer}${this.handynummerEingabe}`;
      }
    },
    validateEmailBestaetigung() {
      if (!this.emailBestaetigung) {
        this.errors.emailBestaetigung = true;
        this.emailBestaetigungFehlerText = 'Bitte bestätigen Sie Ihre E-Mail-Adresse.';
      } else if (this.emailBestaetigung !== this.emailAdresse) {
        this.errors.emailBestaetigung = true;
        this.emailBestaetigungFehlerText = 'Entspricht nicht der E-Mail-Adresse.';
      } else {
        this.errors.emailBestaetigung = false;
      }
    },
    validateNutzungsbedingungen(istLeerCheck) {
      if (!this.nutzungsbedingung && istLeerCheck) {
        this.errors.nutzungsbedingung = true;
      } else {
        this.errors.nutzungsbedingung = false;
      }
    },
    validateEinwilligungKsp(istLeerCheck) {
      if (!this.einwilligungKsp && istLeerCheck) {
        this.errors.einwilligungKsp = true;
      } else {
        this.errors.einwilligungKsp = false;
      }
    },
    resetValidierung() {
      this.errors.vorname = false;
      this.errors.nachname = false;
      this.errors.emailAdresse = false;
      this.errors.emailBestaetigung = false;
      this.errors.handynummer = false;
      this.errors.vsnr = false;
      this.errors.nutzungsbedingung = false;
      this.errors.einwilligungKsp = false;
      this.backendFehler = false;
    },
  },
  watch: {
    vorname() {
      this.validateVorname();
    },
    nachname() {
      this.validateNachname();
    },
    nutzungsbedingung() {
      this.validateNutzungsbedingungen();
    },
    einwilligungKsp() {
      this.validateEinwilligungKsp();
    },
  },
};
</script>
<style scoped>
.button-disabled, .button-disabled:hover {
  opacity: 0.3;
  box-shadow: none;
  cursor: default;
}

#submit-btn {
  font-weight: inherit;
}

.vgh-eingabe-form:focus-within {
  border: 1px solid #0558f0;
}

.oevo-eingabe-form:focus-within {
  border: 1px solid #004494;
}

.oesa-eingabe-form:focus-within {
  border: 1px solid #78bfa4;
}

</style>
